.spc_list li {
  font-family: Inter !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  padding-left: 5% !important;
  align-items: center !important;
  color: #838899 !important;
}
.spc_headsader {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;

  color: #2f2a26 !important;
}

.spc_head {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;

  display: flex;
  align-items: center;

  color: #2f2a26 !important;
}

.spc_text{
  font-size: 14px !important;
  margin-bottom: 13px !important;
}
