.ql-container {
  max-height: 13rem !important;
  overflow-y: scroll !important;
}

.css-q8hpuo-MuiFormControl-root {
  height: 38px;
  border-radius: 4px;
  border: 1px solid;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 38px;
  border-radius: 4px;
}

.css-1yhpg23-MuiTableCell-root {
  padding: 0px !important;
  text-align: center !important;
}

.css-1ndpvdd-MuiTableCell-root {
  text-align: center !important;
  margin: auto !important;
  padding-left: 40px !important;
}

.ck-editor .ck-content {
  min-height: 200px;
}

.file-button{
  height: 40px;
  background-color: #4caf50;
  border-radius: 20px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-button:hover{
  background-color: rgba(77, 140, 79, 0.8);
  height: 38px;
  width: 163px;
}

.profile-label{
  cursor: pointer;
  position: relative !important;
}

.remove-pic{
  text-transform: none !important;
  border-radius: 2px !important;
  white-space: nowrap !important;
  background-color: rgba(140, 18, 68) !important;
  box-shadow:rgb(55, 55, 55) 0px 0px 18px 0px !important;
  position: absolute !important;
  left: 9px;
  opacity: 0;
}

.profile-label:hover >.remove-pic{
  opacity: 1;
  transition: all 0.5s ease 0s;
}

.support-text ul{
  list-style-type: disc;
  margin-left: 45px;
}