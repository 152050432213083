
.textEditor ul {
  display: block;
  list-style-type: disc !important;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 40px;
}

.textEditor ol {
  display: block;
  list-style-type: auto !important;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 40px;
}
#Alltables ul {
  list-style: disc  !important;
  -webkit-margin-before: 1em !important;
  -webkit-margin-after: 1em !important;
  -webkit-margin-start: 0px !important;
  -webkit-margin-end: 0px !important;
  -webkit-padding-start: 40px !important;
}
#Alltables ol {
  list-style: auto  !important;
  -webkit-margin-before: 1em !important; 
  -webkit-margin-after: 1em !important;
  -webkit-margin-start: 0px !important;
  -webkit-margin-end: 0px !important;
  -webkit-padding-start: 40px !important;
}

#Alltables table tr td{
  border: 1px solid #dadada !important;
  padding: 0.4em !important;
}

#Alltables{
  width: 100% !important;
}

.jodit-add-new-line_after{
  
  display: none !important;
}

/* table tr td {
  border: 1px solid #dadada;
  min-width: 2em;
  padding: 0.4em;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  vertical-align: middle;
} */
