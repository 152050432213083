.spc_supportheaderdiv {
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover !important;
  width: 100% !important;
}
.spc_supportheaderdiv_noborder {
  background: url('../../assets/images/DocumentationBG.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover !important;
  width: 100% !important;
  height: 650px !important;
}
.spc_suppportHeroheading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 25px !important;
  padding-top: 5% !important;
  text-align: center !important;
  letter-spacing: -0.015em !important;
}
.dotIcon{
  text-align: right !important;
  position: relative !important;
  top: -47% !important;
}
.spc_title {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  /* identical to box height, or 30px */
  padding-left: 2% !important;
  display: flex !important;
  align-items: center !important;

  color: #000000 !important;
}
.spc_active {
  background: #f76820 !important;
}
.spc_nonactive {
  background: #abadb5 !important;
}
@media only screen and (max-width: 600px) {
  .spc_suppportHeroheading {
    font-size: 28px !important;
    padding-top: 20% !important;
  }

  .spc_doctitle {
    font-size: 13px !important;
  }
  .spc_title {
    font-size: 18px !important;
  }
}
