/* Importing all CSS files */

@import url("./components/index.css");
@import url("./container/index.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* Defaults */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Calibri", sans-serif;
}
.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root{
  padding: 0px !important;
}
/* for live */
.css-xxees4 .MuiOutlinedInput-root{
  padding: 0px !important;
}
.css-igs3ac{
  border: 1px solid #e5e7eb !important;

}
fieldset{
  border: 1px solid #e5e7eb !important;

}
/*  */

.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: 1px solid #e5e7eb !important;
  

}



.autocomplte:hover{
  padding: 0px !important;
  
  
}

.css-185gdzj-MuiCardHeader-root{
  padding: 9px !important;
}

/* Primary Variables */
:root {
  --primary: "green";
}
body {
  background: #ffffff !important;
}
/* Change the white to any color */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
