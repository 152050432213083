.home-container{
    padding:20px;
}

/* .home-upper-container{
    display: flex;
} */

.home-left-container{
    width: 100%;
}
.home-right-container{
    width: 100%;
}

.no-subscription{
    display: grid;
    place-items: center;
    height: 100%;
}

.no-subscription-text{
    font-size: 32px;
    font-weight: 600;
    color:#8C1244;
    
}

.btn-container{
    margin: 10px;
    width:99%;
    display: flex;
    justify-content: flex-end;
}