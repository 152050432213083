.cardDiv{
    background: #FFFFFF;
    opacity: 0.9;
    box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 20px !important;
   
    background: #FFFFFF;
    border-radius: 20px;
   
    overflow: hidden !important;



    
}
.dashboard-main
{
 
 padding-left: 10px;
    padding-right: 10px;
}
.dashboard_second
{
  
    padding-left: 10px;
    padding-right: 10px;
}

.cardh3
{
    /* font-family: 'sana'; */
font-style: normal;
font-weight: 500;
padding-top: 24px !important;
padding-left: 35px !important;
font-size: 24px;
line-height: 36px;

color: #000000;

}
.cardh1
{
    width: 100%;
height: 54px;


font-style: normal;
font-weight: 600;
font-size: 36px;
line-height: 54px;
text-align: end;
padding-right: 30px;
margin-top: 14px;
color: #000000;
}
.cardP
{
    width: 100%;
    height: 34px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 156.19%;
    color: #888888;
    margin-bottom: 30px;
    text-align: end;
    padding-right: 27px;
}

.progress_div{

 width: 100%;
 padding: 25px;
 background: #FFFFFF;
border: 0.704px solid rgba(109, 125, 147, 0.15);
box-shadow: 5.632px 5.632px 28.16px -14.08px rgba(0, 0, 0, 0.1);
margin-top: 10px;
border-radius: 8.448px;
}

.progress_div-main
{
    padding: 20px;
}

.linhr
{
    /* position: absolute; */
width: 100%;
margin-top: 16px;
height: 0px;
/* left: 1px; */
/* top: 74px; */

border: 0.5px solid rgba(31, 41, 55, 0.25);
}