.contactform {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.inputfield {
  /* font-family: "poppins"; */
  width: 50%;
  height: 60px;
  margin-bottom: 5%;
  border: none;
  filter: drop-shadow(0px 7px 17px rgba(255, 118, 49, 0.3));
  color: #5e5c5f;
  font-size: 18px;
  padding-left: 3%;
  border-radius: 10px;
}

input::placeholder {
  color: #5e5c5f;
}
.contactsvg {
  width: 90%;
}
.textarea {
  border: none;
  filter: drop-shadow(0px 7px 17px rgba(255, 118, 49, 0.3));
  color: #5e5c5f;
  font-size: 18px;
  padding-left: 3%;
  width: 50%;
  height: 222px;
  border-radius: 10px;
  padding-top: 2%;
}
textarea::placeholder {
  color: #5e5c5f;
}
.Contactusbutton {
  width: 160px;
  height: 50px;
  background: #8c1244 !important;
  border-radius: 10px;
  text-transform: capitalize !important;
}
.borderimg {
  width: 2% !important;
  height: 70vh !important;
  position: absolute !important;
  /* right: 13%; */
  left: 9% !important;
}

@media only screen and (max-width: 900px) {
  .textarea {
    width: 80% !important;
  }
  .inputfield {
    width: 80% !important;
  }
  .contactsvg {
    width: 100% !important;
  }
  .borderimg {
    height: 55% !important;
    left: 5% !important;
  }
  .contactbuttondiv {
    margin-left: 0%;
  }
}

@media only screen and (max-width: 600px) {
  .contactform {
    margin-top: 5%;
  }
  .textarea {
    width: 80% !important;
    font-size: 16px !important;
    margin-bottom: 0% !important;
  }
  .inputfield {
    width: 80% !important;
    font-size: 16px !important;
  }
  .contactsvg {
    width: 100% !important;
    padding-top: 5% !important;
  }
  .borderimg {
    height: 60% !important;
    left: 4% !important;
  }
  .Contactusbutton {
    margin-top: 5% !important;
  }
}
